import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'
import Layout2 from '../views/layout/Layout2'
/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/
export const constantRouterMap = [
  { path: '/login', component: () => import('@/views/login/index'), hidden: true },
  { path: '/404', component: () => import('@/views/404'), hidden: true },
  {
    path: '',
    component: Layout,
    redirect: '/welcome',
    children: [{
      path: 'welcome',
      name: 'welcome',
      component: () => import('@/views/home/welcome'),
      meta: { title: '欢迎页', icon: 'home' }
    }]
  }
]

export const asyncRouterMap = [
  // 商城数据板
  {
    path: '/ebet',
    component: Layout,
    redirect: '/ebet/home',
    name: 'ebet',
    meta: { title: '商城数据板', icon: 'home' },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home/index'),
        meta: { title: '商城首页', icon: 'home' }
      },
      {
        path: '/ebet/overview',
        name: 'overview',
        component: Layout2,
        meta: { title: '整体概况', icon: 'cms-information' },
        children: [
          {
            id: 170,
            path: 'board',
            name: 'board',
            component: () => import('@/views/home/overview/board.vue'),
            meta: { title: '数据看板', icon: 'cms-information' },
          },
          {
            id: 171,
            path: 'useOverview',
            name: 'useOverview',
            component: () => import('@/views/home/overview/useOverview.vue'),
            meta: { title: '使用概况', icon: 'cms-information' },
          },
        ]
      },
      {
        path: 'userData',
        name: 'userData',
        component: () => import('@/views/home/userData'),
        meta: { title: '商城用户数据', icon: 'cms-information' }
      },
    ]
  },
  // 商品
  {
    path: '/pms',
    component: Layout,
    redirect: '/pms/product',
    name: 'pms',
    meta: { title: '商品', icon: 'product' },
    children: [
      // {
      //   path: 'product',
      //   name: 'product',
      //   component: () => import('@/views/pms/product/index'),
      //   meta: { title: '商品列表', icon: 'product-list' }
      // },
      {
        path: 'addProduct',
        name: 'addProduct',
        component: () => import('@/views/pms/product/add'),
        meta: { title: '添加商品', icon: 'product-add' }
      },
      {
        path: 'updateProduct',
        name: 'updateProduct',
        component: () => import('@/views/pms/product/update'),
        meta: { title: '修改商品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'examineProduct',
        name: 'examineProduct',
        component: () => import('@/views/pms/product/examine'),
        meta: { title: '查看商品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'productCate',
        name: 'productCate',
        component: () => import('@/views/pms/productCate/index'),
        meta: { title: '商品分类', icon: 'product-cate' }
      },
      {
        path: 'addProductCate',
        name: 'addProductCate',
        component: () => import('@/views/pms/productCate/add'),
        meta: { title: '添加商品分类' },
        hidden: true
      },
      {
        path: 'updateProductCate',
        name: 'updateProductCate',
        component: () => import('@/views/pms/productCate/update'),
        meta: { title: '修改商品分类' },
        hidden: true
      },
      {
        path: 'productAttr',
        name: 'productAttr',
        component: () => import('@/views/pms/productAttr/index'),
        meta: { title: '商品类型', icon: 'product-attr' }
      },
      {
        path: 'productAttrList',
        name: 'productAttrList',
        component: () => import('@/views/pms/productAttr/productAttrList'),
        meta: { title: '商品属性列表' },
        hidden: true
      },
      {
        path: 'addProductAttr',
        name: 'addProductAttr',
        component: () => import('@/views/pms/productAttr/addProductAttr'),
        meta: { title: '添加商品属性' },
        hidden: true
      },
      {
        path: 'updateProductAttr',
        name: 'updateProductAttr',
        component: () => import('@/views/pms/productAttr/updateProductAttr'),
        meta: { title: '修改商品属性' },
        hidden: true
      },
    ]
  },
  //供应商中心
  {
    path: '/supplierCentre',
    component: Layout,
    redirect: '/supplierCentre/datapad',
    name: 'supplierCentre',
    meta: { title: '供应商中心', icon: 'supply_demand' },
    children: [
      {
        path: 'datapad',
        name: 'datapad',
        component: () => import('@/views/supplierCentre/datapad.vue'),
        meta: { title: '数据看板', icon: 'cms-information' },
      },
      {
        path: 'supplierList',
        name: 'supplierList',
        component: () => import('@/views/supplierCentre/supplier/index'),
        meta: { title: '供应商列表', icon: 'product-brand' }
      },
      {
        path: 'addSpplier',
        name: 'addSpplier',
        component: () => import('@/views/supplierCentre/supplier/add'),
        meta: { title: '添加供应商' },
        hidden: true
      },
      {
        path: 'updateSpplier',
        name: 'updateSpplier',
        component: () => import('@/views/supplierCentre/supplier/update'),
        meta: { title: '编辑供应商' },
        hidden: true
      },
      {
        path: 'productsList',
        name: 'productsList',
        component: () => import('@/views/supplierCentre/products/index'),
        meta: { title: '产品列表', icon: 'product-brand' },
        hidden: true
      },
      {
        path: 'addProducts',
        name: 'addProducts',
        component: () => import('@/views/supplierCentre/products/add'),
        meta: { title: '添加产品' },
        hidden: true
      },
      {
        path: 'updateProducts',
        name: 'updateProducts',
        component: () => import('@/views/supplierCentre/products/update'),
        meta: { title: '编辑产品' },
        hidden: true
      },
      {
        path: 'updateList',
        name: 'updateList',
        component: () => import('@/views/supplierCentre/updateList'),
        meta: { title: '产品更新记录列表', icon: 'product-brand' },
        hidden: true
      },
    ]
  },
  // 订单
  {
    path: '/oms',
    component: Layout,
    redirect: '/oms/order',
    name: 'oms',
    meta: { title: '订单', icon: 'order' },
    children: [
      {
        path: 'order',
        name: 'order',
        component: () => import('@/views/oms/order/index'),
        meta: { title: '订单列表', icon: 'product-list' }
      },
      {
        path: 'orderDetail',
        name: 'orderDetail',
        component: () => import('@/views/oms/order/orderDetail'),
        meta: { title: '订单详情' },
        hidden: true
      },
      {
        path: 'deliverOrderList',
        name: 'deliverOrderList',
        component: () => import('@/views/oms/order/deliverOrderList'),
        meta: { title: '发货列表' },
        hidden: true
      },
      {
        path: 'orderSetting',
        name: 'orderSetting',
        component: () => import('@/views/oms/order/setting'),
        meta: { title: '订单设置', icon: 'order-setting' }
      },
      {
        path: 'returnApply',
        name: 'returnApply',
        component: () => import('@/views/oms/apply/index'),
        meta: { title: '退货申请处理', icon: 'order-return' }
      },
      {
        path: 'returnReason',
        name: 'returnReason',
        component: () => import('@/views/oms/apply/reason'),
        meta: { title: '退货原因设置', icon: 'order-return-reason' }
      },
      {
        path: 'returnApplyDetail',
        name: 'returnApplyDetail',
        component: () => import('@/views/oms/apply/applyDetail'),
        meta: { title: '退货原因详情' },
        hidden: true
      }
    ]
  },
  // 快易购管理
  {
    path: '/kygManagement',
    component: Layout,
    redirect: '/kygManagement/kygHome',
    name: 'kygManagement',
    meta: { title: '快易购管理', icon: 'kyg-management' },
    children: [
      {
        path: 'kygHome',
        name: 'kygHome',
        component: () => import('@/views/kygManagement/home.vue'),
        meta: { title: '快易购首页', icon: 'home' }
      },
      {
        path: '/kygManagement/kygOverview',
        name: 'kygOverview',
        component: Layout2,
        meta: { title: '快易购整体概况', icon: 'cms-information' },
        children: [
          {
            id: 162,
            path: 'kygBoard',
            name: 'kygBoard',
            component: () => import('@/views/kygManagement/kygOverview/kygBoard.vue'),
            meta: { title: '数据看板', icon: 'cms-information' },
          },
          {
            id: 163,
            path: 'kygUseOverview',
            name: 'kygUseOverview',
            component: () => import('@/views/kygManagement/kygOverview/kygUseOverview.vue'),
            meta: { title: '使用概况', icon: 'cms-information' },
          },
        ]
      },
      {
        path: 'kygUserData',
        name: 'kygUserData',
        component: () => import('@/views/kygManagement/kygUserData'),
        meta: { title: '快易购用户数据', icon: 'cms-information' }
      },
      {
        path: '/kygManagement/kygTransform',
        name: 'kygTransform',
        component: Layout2,
        meta: { title: '快易购事件转化', icon: 'cms-information' },
        children: [
          {
            id: 173,
            path: 'sample',
            name: 'sample',
            component: () => import('@/views/kygManagement/kygTransform/sample.vue'),
            meta: { title: '索样', icon: 'cms-information' },
          },
          {
            id: 174,
            path: 'keyword',
            name: 'keyword',
            component: () => import('@/views/kygManagement/kygTransform/keyword.vue'),
            meta: { title: '入篮/关键词', icon: 'cms-information' },
          },
          {
            id: 175,
            path: 'productSituation',
            name: 'productSituation',
            component: () => import('@/views/kygManagement/kygTransform/productSituation.vue'),
            meta: { title: '产品情况', icon: 'cms-information' },
          },
        ]
      },
      {
        path: 'brandList',
        name: 'brandList',
        component: () => import('@/views/kygManagement/brand/index'),
        meta: { title: '品牌管理', icon: 'product-brand' }
      },
      {
        path: 'addBrand',
        name: 'addBrand',
        component: () => import('@/views/kygManagement/brand/add'),
        meta: { title: '添加品牌' },
        hidden: true
      },
      {
        path: 'updateBrand',
        name: 'updateBrand',
        component: () => import('@/views/kygManagement/brand/update'),
        meta: { title: '编辑品牌' },
        hidden: true
      },
      {
        path: 'brandContentManagement',
        name: 'brandContentManagement',
        component: () => import('@/views/kygManagement/brandContentManagement/index.vue'),
        meta: { title: '品牌内容管理' },
        hidden: true
      },
      {
        path: 'addBrandContent',
        name: 'addBrandContent',
        component: () => import('@/views/kygManagement/brandContentManagement/add.vue'),
        meta: { title: '添加品牌内容', },
        hidden: true
      },
      {
        path: 'updateBrandContent',
        name: 'updateBrandContent',
        component: () => import('@/views/kygManagement/brandContentManagement/update.vue'),
        meta: { title: '修改品牌内容', },
        hidden: true
      },
      {
        path: 'brandProductList',
        name: 'brandProductList',
        component: () => import('@/views/kygManagement/brandContentManagement/brandProductList/index.vue'),
        meta: { title: '品牌内容管理' },
        hidden: true
      },
      {
        path: 'addBrandProduct',
        name: 'addBrandProduct',
        component: () => import('@/views/kygManagement/brandContentManagement/brandProductList/add.vue'),
        meta: { title: '添加品牌内容', },
        hidden: true
      },
      {
        path: 'updateBrandProduct',
        name: 'updateBrandProduct',
        component: () => import('@/views/kygManagement/brandContentManagement/brandProductList/update.vue'),
        meta: { title: '修改品牌内容', },
        hidden: true
      },
      {
        path: 'kygRecommend',
        name: 'kygRecommend',
        component: () => import('@/views/kygManagement/kygRecommend/index.vue'),
        meta: { title: '快易购推荐位管理', icon: 'kyg-recommend' }
      },
      {
        path: 'addRecommend',
        name: 'addRecommend',
        component: () => import('@/views/kygManagement/kygRecommend/add.vue'),
        meta: { title: '添加推荐', icon: 'kyg-recommend' },
        hidden: true
      },
      {
        path: 'updateRecommend',
        name: 'updateRecommend',
        component: () => import('@/views/kygManagement/kygRecommend/update.vue'),
        meta: { title: '修改推荐', icon: 'kyg-recommend' },
        hidden: true
      },
      {
        path: 'kygHomeBrandsType',
        name: 'kygHomeBrandsType',
        component: () => import('@/views/kygManagement/kygRecommend/kygHomeBrandsType.vue'),
        meta: { title: '首页品牌分类管理', icon: 'kyg-recommend' },
        hidden: true
      },
      {
        path: 'brandApplyList',
        name: 'brandApplyList',
        component: () => import('@/views/kygManagement/brandApplyList.vue'),
        meta: { title: '品牌申请列表', icon: 'kyg-recommend' }
      },
      {
        path: 'kygCableSampleList',
        name: 'kygCableSampleList',
        component: () => import('@/views/kygManagement/kygCableSampleList.vue'),
        meta: { title: '索样列表', icon: 'kyg-recommend' }
      },
      {
        path: 'kygEntrustList',
        name: 'kygEntrustList',
        component: () => import('@/views/kygManagement/kygEntrustList.vue'),
        meta: { title: '集采委托列表', icon: '  ' }
      },
      {
        path: 'kygMediaLibrary',
        name: 'kygMediaLibrary',
        component: () => import('@/views/kygManagement/kygMediaLibrary/index.vue'),
        meta: { title: '媒体库', icon: 'media-library' }
      },
      {
        path: 'tdkManagement',
        name: 'tdkManagement',
        component: () => import('@/views/kygManagement/tdkManagement/index.vue'),
        meta: { title: 'Tdk管理', icon: 'tdk-management' }
      },
      {
        path: 'addTdk',
        name: 'addTdk',
        component: () => import('@/views/kygManagement/tdkManagement/add.vue'),
        meta: { title: '添加Tdk', icon: 'tdk-management' },
        hidden: true
      },
      {
        path: 'updateTdk',
        name: 'updateTdk',
        component: () => import('@/views/kygManagement/tdkManagement/update.vue'),
        meta: { title: '修改Tdk', icon: 'tdk-management' },
        hidden: true
      },
      {
        path: 'kygInformation',
        name: 'kygInformation',
        component: () => import('@/views/kygManagement/information/index.vue'),
        meta: { title: '资讯列表', icon: 'cms-information' }
      },
      {
        path: 'addKygInformation',
        name: 'addKygInformation',
        component: () => import('@/views/kygManagement/information/add.vue'),
        meta: { title: '添加资讯', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateKygInformation',
        name: 'updateKygInformation',
        component: () => import('@/views/kygManagement/information/update.vue'),
        meta: { title: '修改资讯', icon: 'product-add' },
        hidden: true
      },
    ]
  },
  // 用户管理
  {
    path: '/sms',
    component: Layout,
    redirect: '/sms/userList',
    name: 'sms',
    meta: { title: '用户管理', icon: 'sms' },
    children: [
      {
        path: 'userList',
        name: 'userList',
        component: () => import('@/views/sms/userList/index'),
        meta: { title: '用户列表', icon: 'user-list' },
      },
      {
        path: 'enterpriseCertification',
        name: 'enterpriseCertification',
        component: () => import('@/views/sms/userList/enterpriseCertification.vue'),
        meta: { title: '提交企业认证', icon: 'user-list' },
        hidden: true
      },
      {
        path: 'updateUser',
        name: 'updateUser',
        component: () => import('@/views/sms/userList/update.vue'),
        meta: { title: '修改用户信息', icon: 'user-list' },
        hidden: true
      },
      {
        path: 'notice',
        name: 'notice',
        component: () => import('@/views/sms/userList/index'),
        meta: { title: '通知' }
      },
      {
        path: 'loginRecordList',
        name: 'loginRecordList',
        component: () => import('@/views/sms/userList/loginRecordList.vue'),
        meta: { title: '用户登录记录列表', icon: 'user-list' },
        hidden: true
      },
      {
        path: 'searchRecordList',
        name: 'searchRecordList',
        component: () => import('@/views/sms/userList/searchRecordList.vue'),
        meta: { title: '用户搜索记录列表', icon: 'user-list' },
        hidden: true
      },
    ]
  },
  // 权限管理
  {
    path: '/ums',
    component: Layout,
    redirect: '/ums/admin',
    name: 'ums',
    meta: { title: '权限', icon: 'ums' },
    children: [
      {
        path: 'admin',
        name: 'admin',
        component: () => import('@/views/ums/admin/index'),
        meta: { title: '用户列表', icon: 'ums-admin' }
      },
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/ums/role/index'),
        meta: { title: '角色列表', icon: 'ums-role' }
      },
      {
        path: 'allocMenu',
        name: 'allocMenu',
        component: () => import('@/views/ums/role/allocMenu'),
        meta: { title: '分配菜单' },
        hidden: true
      },
      {
        path: 'allocResource',
        name: 'allocResource',
        component: () => import('@/views/ums/role/allocResource'),
        meta: { title: '分配资源' },
        hidden: true
      },
      {
        path: 'menu',
        name: 'menu',
        component: () => import('@/views/ums/menu/index'),
        meta: { title: '菜单列表', icon: 'ums-menu' }
      },
      {
        path: 'addMenu',
        name: 'addMenu',
        component: () => import('@/views/ums/menu/add'),
        meta: { title: '添加菜单' },
        hidden: true
      },
      {
        path: 'updateMenu',
        name: 'updateMenu',
        component: () => import('@/views/ums/menu/update'),
        meta: { title: '修改菜单' },
        hidden: true
      },
      {
        path: 'resource',
        name: 'resource',
        component: () => import('@/views/ums/resource/index'),
        meta: { title: '资源列表', icon: 'ums-resource' }
      },
      {
        path: 'resourceCategory',
        name: 'resourceCategory',
        component: () => import('@/views/ums/resource/categoryList'),
        meta: { title: '资源分类' },
        hidden: true
      },
      {
        path: 'dictionary',
        name: 'dictionary',
        component: () => import('@/views/ums/dictionary/index'),
        meta: { title: '字典管理', icon: 'dictionary' }
      },
      {
        path: 'bannedList',
        name: 'bannedList',
        component: () => import('@/views/ums/bannedList/index'),
        meta: { title: '封禁列表', icon: 'banned' }
      },
      {
        path: 'securityPolicy',
        name: 'securityPolicy',
        component: () => import('@/views/ums/bannedList/securityPolicy'),
        meta: { title: '安全策略' },
        hidden: true
      },
      {
        path: 'logList',
        name: 'logList',
        component: () => import('@/views/ums/logList/index'),
        meta: { title: '封禁列表', icon: 'log-list' }
      },
    ]
  },
  // 客服中心
  {
    path: '/service',
    component: Layout,
    redirect: '/service/IM',
    name: 'service',
    meta: { title: '客服中心', icon: 'ums' },
    children: [
      {
        path: 'IM',
        name: 'IM',
        component: () => import('@/views/service/IM/IM'),
        meta: { title: 'IM 即时通讯', icon: 'im' }
      },
      {
        path: 'notice',
        name: 'notice',
        component: () => import('@/views/service/notice/index'),
        meta: { title: '通知', icon: 'notice' }
      },
      {
        path: 'live',
        name: 'live',
        component: () => import('@/views/service/live/pusher/index'),
        meta: { title: '直播', icon: 'live' }
      },
      {
        path: 'pushLive',
        name: 'pushLive',
        component: () => import('@/views/service/live/pusher/index'),
        meta: { title: '发起直播', icon: 'live' },
        hidden: true
      },
      {
        path: 'playLive',
        name: 'playLive',
        component: () => import('@/views/service/live/player/index'),
        meta: { title: '观看直播', icon: 'live' },
        hidden: true
      },
      {
        path: 'userFeedback',
        name: 'userFeedback',
        component: () => import('@/views/service/userFeedback.vue'),
        meta: { title: '用户反馈', icon: 'userFeedback' }
      },
    ]
  },
  // 信息管理
  {
    path: '/audit',
    component: Layout,
    redirect: '/audit/auditList',
    name: 'audit',
    meta: { title: '审核中心', icon: 'audit' },
    children: [
      {
        path: 'auditList',
        name: 'auditList',
        component: () => import('@/views/audit/index'),
        meta: { title: '供应商认证', icon: 'audit' }
      },
      {
        path: 'personalReview',
        name: 'personalReview',
        component: () => import('@/views/audit/personalReview'),
        meta: { title: '个人信息认证', icon: 'audit' }
      },
      {
        path: 'myAudit',
        name: 'myAudit',
        component: () => import('@/views/audit/myAudit'),
        meta: { title: '我的审核任务', icon: 'notice' }
      },
      {
        path: 'auditDetails',
        name: 'auditDetails',
        component: () => import('@/views/audit/auditDetails'),
        meta: { title: '审核详情', icon: 'notice' },
        hidden: true
      },
      {
        path: 'product',
        name: 'product',
        component: () => import('@/views/audit/product/index'),
        meta: { title: '商品列表', icon: 'product-list' }
      },
      {
        path: 'examineProduct',
        name: 'examineProduct',
        component: () => import('@/views/audit/product/examine'),
        meta: { title: '查看商品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'storeList',
        name: 'storeList',
        component: () => import('@/views/audit/store/store.vue'),
        meta: { title: '店铺列表', icon: 'store' }
      },
      {
        path: 'financeList',
        name: 'financeList',
        component: () => import('@/views/audit/finance/financeList.vue'),
        meta: { title: '金融列表', icon: 'serviceOrder' }
      },
      {
        path: 'bidding',
        name: 'bidding',
        component: () => import('@/views/audit/bidding/index.vue'),
        meta: { title: '竞价列表', icon: 'bidding' }
      },
      {
        path: 'updateBidding',
        name: 'updateBidding',
        component: () => import('@/views/audit/bidding/update.vue'),
        meta: { title: '修改竞价', icon: 'bidding' },
        hidden: true
      },
      {
        path: 'clearance',
        name: 'clearance',
        component: () => import('@/views/audit/clearance/index.vue'),
        meta: { title: '清仓列表', icon: 'clearance' }
      },
      {
        path: 'updateClearance',
        name: 'updateClearance',
        component: () => import('@/views/audit/clearance/update.vue'),
        meta: { title: '修改清仓', icon: 'clearance' },
        hidden: true
      },
      {
        path: 'barter',
        name: 'barter',
        component: () => import('@/views/audit/barter/index.vue'),
        meta: { title: '易货列表', icon: 'barter' }
      },
      {
        path: 'institutionsList',
        name: 'institutionsList',
        component: () => import('@/views/audit/institutions/index.vue'),
        meta: { title: '定制机构认证', icon: 'institutions' }
      },
      {
        path: 'customSynthesisList',
        name: 'customSynthesisList',
        component: () => import('@/views/audit/customSynthesis/index.vue'),
        meta: { title: '定制合成列表', icon: 'custom' }
      },
      {
        path: 'physicalList',
        name: 'physicalList',
        component: () => import('@/views/audit/physical/index.vue'),
        meta: { title: '体检审核列表', icon: 'physical' }
      },
      {
        path: 'brandedShopApplication',
        name: 'brandedShopApplication',
        component: () => import('@/views/audit/brandedShopApplication.vue'),
        meta: { title: '品牌店铺申请列表', icon: 'store' }
      },
      {
        path: 'vipApplication',
        name: 'vipApplication',
        component: () => import('@/views/audit/vipApplication.vue'),
        meta: { title: '采购通VIP申请', icon: 'vip' }
      },
    ]
  },
  // 企业站
  {
    path: '/enterprise',
    component: Layout,
    redirect: '/enterprise/newsList',
    name: 'enterprise',
    meta: { title: '新闻', icon: 'product' },
    children: [
      {
        path: 'newsList',
        name: 'newsList',
        component: () => import('@/views/enterprise/news/index.vue'),
        meta: { title: '新闻列表', icon: 'newsList' }
      },
      {
        path: 'addNews',
        name: 'addNews',
        component: () => import('@/views/enterprise/news/add.vue'),
        meta: { title: '添加新闻', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateNews',
        name: 'updateNews',
        component: () => import('@/views/enterprise/news/update.vue'),
        meta: { title: '修改新闻', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'productList',
        name: 'productList',
        component: () => import('@/views/enterprise/product/index.vue'),
        meta: { title: '产品列表', icon: 'newsList' }
      },
      {
        path: 'addproduct',
        name: 'addproduct',
        component: () => import('@/views/enterprise/product/add.vue'),
        meta: { title: '添加产品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateProduct',
        name: 'updateProduct',
        component: () => import('@/views/enterprise/product/update.vue'),
        meta: { title: '修改产品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'supplyApply',
        name: 'supplyApply',
        component: () => import('@/views/enterprise/supplyApply/index.vue'),
        meta: { title: '申请列表', icon: 'supplyApply' }
      },
      {
        path: 'supplyDetails',
        name: 'supplyDetails',
        component: () => import('@/views/enterprise/supplyApply/supplyDetails.vue'),
        meta: { title: '申请详情', icon: 'supplyApply' },
        hidden: true
      },
    ]
  },
  // 内容管理
  {
    path: '/cms',
    component: Layout,
    redirect: '/cms/information',
    name: 'cms',
    meta: { title: '内容管理', icon: 'product' },
    children: [
      {
        path: 'information',
        name: 'information',
        component: () => import('@/views/cms/information/index.vue'),
        meta: { title: '资讯列表', icon: 'cms-information' }
      },
      {
        path: 'addInformation',
        name: 'addInformation',
        component: () => import('@/views/cms/information/add.vue'),
        meta: { title: '添加资讯', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateInformation',
        name: 'updateInformation',
        component: () => import('@/views/cms/information/update.vue'),
        meta: { title: '修改资讯', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'aiGenerated',
        name: 'aiGenerated',
        component: () => import('@/views/cms/aiGenerated/index.vue'),
        meta: { title: 'AI生成', icon: 'ai' }
      },
      {
        path: 'aiKeyList',
        name: 'aiKeyList',
        component: () => import('@/views/cms/aiGenerated/keyList.vue'),
        meta: { title: 'API_KEY管理', icon: 'ai' },
        hidden: true
      },
      {
        path: 'aiKeywordList',
        name: 'aiKeywordList',
        component: () => import('@/views/cms/aiGenerated/keywordList.vue'),
        meta: { title: '关键词管理', icon: 'ai' },
        hidden: true
      },
      {
        path: 'policy',
        name: 'policy',
        component: () => import('@/views/cms/policy/index.vue'),
        meta: { title: '政策列表', icon: 'newsList' }
      },
      {
        path: 'addPolicy',
        name: 'addPolicy',
        component: () => import('@/views/cms/policy/add.vue'),
        meta: { title: '添加政策', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'updatePolicy',
        name: 'updatePolicy',
        component: () => import('@/views/cms/policy/update.vue'),
        meta: { title: '修改政策', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'bulletin',
        name: 'bulletin',
        component: () => import('@/views/cms/bulletin/index.vue'),
        meta: { title: '公告列表', icon: 'newsList' }
      },
      {
        path: 'addBulletin',
        name: 'addBulletin',
        component: () => import('@/views/cms/bulletin/add.vue'),
        meta: { title: '添加公告', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'updateBulletin',
        name: 'updateBulletin',
        component: () => import('@/views/cms/bulletin/update.vue'),
        meta: { title: '修改公告', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'guideList',
        name: 'guideList',
        component: () => import('@/views/cms/guide/index.vue'),
        meta: { title: '指南列表', icon: 'newsList' }
      },
      {
        path: 'addGuide',
        name: 'addGuide',
        component: () => import('@/views/cms/guide/add.vue'),
        meta: { title: '添加指南', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'updateGuide',
        name: 'updateGuide',
        component: () => import('@/views/cms/guide/update.vue'),
        meta: { title: '修改指南', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'exhibition',
        name: 'exhibition',
        component: () => import('@/views/cms/exhibition/index.vue'),
        meta: { title: '展会列表', icon: 'newsList' }
      },
      {
        path: 'addExhibition',
        name: 'addExhibition',
        component: () => import('@/views/cms/exhibition/add.vue'),
        meta: { title: '添加展会', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'updatEexhibition',
        name: 'updatEexhibition',
        component: () => import('@/views/cms/exhibition/update.vue'),
        meta: { title: '修改展会', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'buildCompany',
        name: 'buildCompany',
        component: () => import('@/views/cms/buildCompany/index.vue'),
        meta: { title: '搭建公司列表', icon: 'newsList' }
      },
      {
        path: 'addBuildCompany',
        name: 'addBuildCompany',
        component: () => import('@/views/cms/buildCompany/add.vue'),
        meta: { title: '添加搭建公司', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'updatBuildCompany',
        name: 'updatBuildCompany',
        component: () => import('@/views/cms/buildCompany/update.vue'),
        meta: { title: '修改搭建公司', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'survey',
        name: 'survey',
        component: () => import('@/views/cms/survey/index.vue'),
        meta: { title: '调查报告列表', icon: 'newsList' }
      },
      {
        path: 'addSurvey',
        name: 'addSurvey',
        component: () => import('@/views/cms/survey/add.vue'),
        meta: { title: '添加报告', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'updatSurvey',
        name: 'updatSurvey',
        component: () => import('@/views/cms/survey/update.vue'),
        meta: { title: '修改报告', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'journal',
        name: 'journal',
        component: () => import('@/views/cms/journal/index.vue'),
        meta: { title: '期刊列表', icon: 'journal' }
      },
      {
        path: 'corporateReleases',
        name: 'corporateReleases',
        component: () => import('@/views/cms/corporateReleases.vue'),
        meta: { title: '企业发布申请列表', icon: 'store' }
      },
      {
        path: 'corporateMessage',
        name: 'corporateMessage',
        component: () => import('@/views/cms/corporateMessage.vue'),
        meta: { title: '企业留言列表', icon: 'order' }
      },
    ]
  },
  // 推荐位管理
  {
    path: '/rms',
    component: Layout,
    redirect: '/rms/recommend',
    name: 'rms',
    meta: { title: '推荐位管理', icon: 'recommend' },
    children: [
      {
        path: 'recommend',
        name: 'recommend',
        component: () => import('@/views/recommend/index.vue'),
        meta: { title: '推荐位管理', icon: 'recommend' }
      },
      {
        path: 'addRecommend',
        name: 'addRecommend',
        component: () => import('@/views/recommend/add.vue'),
        meta: { title: '添加推荐', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateRecommend',
        name: 'updateRecommend',
        component: () => import('@/views/recommend/update.vue'),
        meta: { title: '修改推荐', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'classification',
        name: 'classification',
        component: () => import('@/views/recommend/classification_management.vue'),
        meta: { title: '分类导航管理', icon: 'product-add' },
        hidden: true
      },
    ]
  },
  // 店铺管理
  {
    path: '/store',
    component: Layout,
    redirect: '/store/storeList',
    name: 'store',
    meta: { title: '店铺管理', icon: 'store' },
    children: [
      {
        path: 'storeList',
        name: 'storeList',
        component: () => import('@/views/store/store.vue'),
        meta: { title: '店铺管理', icon: 'store' }
      },
    ]
  },
  // 化工圈管理
  {
    path: '/forum',
    component: Layout,
    redirect: '/forum/problemList',
    name: 'forum',
    meta: { title: '化工圈管理', icon: 'forum' },
    children: [
      {
        path: 'problemList',
        name: 'problemList',
        component: () => import('@/views/forum/problemList.vue'),
        meta: { title: '问题列表', icon: 'forum' }
      },
      {
        path: 'replyList',
        name: 'replyList',
        component: () => import('@/views/forum/replyList.vue'),
        meta: { title: '回答列表', icon: 'forum' },
        hidden: true
      },
    ]
  },
  // 服务订单
  {
    path: '/serviceOrder',
    component: Layout,
    redirect: '/serviceOrder/productSurvpeyList',
    name: 'serviceOrder',
    meta: { title: '服务订单', icon: 'forum' },
    children: [
      {
        path: 'productSurvpeyList',
        name: 'productSurvpeyList',
        component: () => import('@/views/serviceOrder/productSurvpeyList.vue'),
        meta: { title: '产品调查列表', icon: 'serviceOrder' }
      },
      {
        path: 'corporateSurveyList',
        name: 'corporateSurveyList',
        component: () => import('@/views/serviceOrder/corporateSurveyList.vue'),
        meta: { title: '企业调查列表', icon: 'serviceOrder' }
      },
      {
        path: 'exhibitionList',
        name: 'exhibitionList',
        component: () => import('@/views/serviceOrder/exhibitionList.vue'),
        meta: { title: '展会列表', icon: 'serviceOrder' }
      },
      {
        path: '/serviceOrder/financial',
        name: 'financial',
        component: Layout2,
        meta: { title: '金融服务', icon: 'serviceOrder' },
        children: [
          {
            id: 125,
            path: 'trayList',
            name: 'trayList',
            component: () => import('@/views/serviceOrder/financial/trayList.vue'),
            meta: { title: '托盘业务', icon: 'serviceOrder' },
          },
          {
            id: 126,
            path: 'factoringList',
            name: 'factoringList',
            component: () => import('@/views/serviceOrder/financial/factoringList.vue'),
            meta: { title: '保理业务', icon: 'serviceOrder' },
          },
          {
            id: 127,
            path: 'financingList',
            name: 'financingList',
            component: () => import('@/views/serviceOrder/financial/financingList.vue'),
            meta: { title: '贸易链融资', icon: 'serviceOrder' },
          },
          {
            id: 128,
            path: 'tripartiteList',
            name: 'tripartiteList',
            component: () => import('@/views/serviceOrder/financial/tripartiteList.vue'),
            meta: { title: '第三方融资', icon: 'serviceOrder' },
          },
        ]
      },
      {
        path: 'creditList',
        name: 'creditList',
        component: () => import('@/views/serviceOrder/creditList.vue'),
        meta: { title: '信用业务列表', icon: 'serviceOrder' }
      },
      {
        path: 'testList',
        name: 'testList',
        component: () => import('@/views/serviceOrder/testList.vue'),
        meta: { title: '检测订单列表', icon: 'serviceOrder' }
      },
    ]
  },
  // 生态链
  {
    path: '/ecologicalChain',
    component: Layout,
    redirect: '/ecologicalChain/landList',
    name: 'ecologicalChain',
    meta: { title: '生态链', icon: 'forum' },
    children: [
      {
        path: 'landList',
        name: 'landList',
        component: () => import('@/views/ecologicalChain/land/index.vue'),
        meta: { title: '土地列表', icon: 'ecologicalChain' }
      },
      {
        path: 'addLand',
        name: 'addLand',
        component: () => import('@/views/ecologicalChain/land/add.vue'),
        meta: { title: '添加土地', icon: 'ecologicalChain' },
        hidden: true
      },
      {
        path: 'updateLand',
        name: 'updateLand',
        component: () => import('@/views/ecologicalChain/land/update.vue'),
        meta: { title: '修改土地', icon: 'ecologicalChain' },
        hidden: true
      },
      {
        path: 'workShopList',
        name: 'workShopList',
        component: () => import('@/views/ecologicalChain/workShop/index.vue'),
        meta: { title: '厂房列表', icon: 'ecologicalChain' }
      },
      {
        path: 'addWorkShop',
        name: 'addWorkShop',
        component: () => import('@/views/ecologicalChain/workShop/add.vue'),
        meta: { title: '添加厂房', icon: 'ecologicalChain' },
        hidden: true
      },
      {
        path: 'updateWorkShop',
        name: 'updateWorkShop',
        component: () => import('@/views/ecologicalChain/workShop/update.vue'),
        meta: { title: '修改厂房', icon: 'ecologicalChain' },
        hidden: true
      },
      {
        path: 'warehouseList',
        name: 'warehouseList',
        component: () => import('@/views/ecologicalChain/warehouse/index.vue'),
        meta: { title: '仓库列表', icon: 'ecologicalChain' }
      },
      {
        path: 'addWarehouse',
        name: 'addWarehouse',
        component: () => import('@/views/ecologicalChain/warehouse/add.vue'),
        meta: { title: '添加仓库', icon: 'ecologicalChain' },
        hidden: true
      },
      {
        path: 'updateWarehouse',
        name: 'updateWarehouse',
        component: () => import('@/views/ecologicalChain/warehouse/update.vue'),
        meta: { title: '修改仓库', icon: 'ecologicalChain' },
        hidden: true
      },
      {
        path: 'officeList',
        name: 'officeList',
        component: () => import('@/views/ecologicalChain/office/index.vue'),
        meta: { title: '办公楼列表', icon: 'ecologicalChain' }
      },
      {
        path: 'addOffice',
        name: 'addOffice',
        component: () => import('@/views/ecologicalChain/office/add.vue'),
        meta: { title: '添加办公楼', icon: 'ecologicalChain' },
        hidden: true
      },
      {
        path: 'updateOffice',
        name: 'updateOffice',
        component: () => import('@/views/ecologicalChain/office/update.vue'),
        meta: { title: '修改办公楼', icon: 'ecologicalChain' },
        hidden: true
      },
      {
        path: 'investmentList',
        name: 'investmentList',
        component: () => import('@/views/ecologicalChain/investment/index.vue'),
        meta: { title: '招商资讯列表', icon: 'ecologicalChain' }
      },
      {
        path: 'addInvestment',
        name: 'addInvestment',
        component: () => import('@/views/ecologicalChain/investment/add.vue'),
        meta: { title: '添加招商资讯', icon: 'ecologicalChain' },
        hidden: true
      },
      {
        path: 'updateInvestment',
        name: 'updateInvestment',
        component: () => import('@/views/ecologicalChain/investment/update.vue'),
        meta: { title: '修改招商资讯', icon: 'ecologicalChain' },
        hidden: true
      },
      {
        path: 'policyList',
        name: 'policyList',
        component: () => import('@/views/ecologicalChain/policy/index.vue'),
        meta: { title: '政策列表', icon: 'ecologicalChain' }
      },
      {
        path: 'addPolicy',
        name: 'addPolicy',
        component: () => import('@/views/ecologicalChain/policy/add.vue'),
        meta: { title: '添加政策', icon: 'ecologicalChain' },
        hidden: true
      },
      {
        path: 'updatePolicy',
        name: 'updatePolicy',
        component: () => import('@/views/ecologicalChain/policy/update.vue'),
        meta: { title: '修改政策', icon: 'ecologicalChain' },
        hidden: true
      },
    ]
  },
  // 农化App管理
  {
    path: '/agrochemical',
    component: Layout,
    redirect: '/agrochemical/information',
    name: 'agrochemical',
    meta: { title: '农化App管理', icon: 'agrochemical' },
    children: [
      {
        path: 'information',
        name: 'information',
        component: () => import('@/views/agrochemical/information/index.vue'),
        meta: { title: '资讯列表', icon: 'cms-information' }
      },
      {
        path: 'addInformation',
        name: 'addInformation',
        component: () => import('@/views/agrochemical/information/add.vue'),
        meta: { title: '添加资讯', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateInformation',
        name: 'updateInformation',
        component: () => import('@/views/agrochemical/information/update.vue'),
        meta: { title: '修改资讯', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'policy',
        name: 'policy',
        component: () => import('@/views/agrochemical/policy/index.vue'),
        meta: { title: '政策列表', icon: 'newsList' }
      },
      {
        path: 'addPolicy',
        name: 'addPolicy',
        component: () => import('@/views/agrochemical/policy/add.vue'),
        meta: { title: '添加政策', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'updatePolicy',
        name: 'updatePolicy',
        component: () => import('@/views/agrochemical/policy/update.vue'),
        meta: { title: '修改政策', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'knowledge',
        name: 'knowledge',
        component: () => import('@/views/agrochemical/knowledge/index.vue'),
        meta: { title: '资讯列表', icon: 'knowledge' }
      },
      {
        path: 'addKnowledge',
        name: 'addKnowledge',
        component: () => import('@/views/agrochemical/knowledge/add.vue'),
        meta: { title: '添加资讯', icon: 'knowledge' },
        hidden: true
      },
      {
        path: 'updateKnowledge',
        name: 'updateKnowledge',
        component: () => import('@/views/agrochemical/knowledge/update.vue'),
        meta: { title: '修改资讯', icon: 'knowledge' },
        hidden: true
      },
      {
        path: 'userList',
        name: 'userList',
        component: () => import('@/views/agrochemical/userList/index.vue'),
        meta: { title: '用户列表', icon: 'user_list' }
      },
      {
        path: 'storeList',
        name: 'storeList',
        component: () => import('@/views/agrochemical/store/store.vue'),
        meta: { title: '店铺列表', icon: 'user_list' }
      },
      {
        path: 'expertList',
        name: 'expertList',
        component: () => import('@/views/agrochemical/expert/index.vue'),
        meta: { title: '专家列表', icon: 'expert' }
      },
      {
        path: 'addExpert',
        name: 'addExpert',
        component: () => import('@/views/agrochemical/expert/add.vue'),
        meta: { title: '添加专家', icon: 'expert' },
        hidden: true
      },
      {
        path: 'updateExpert',
        name: 'updateExpert',
        component: () => import('@/views/agrochemical/expert/update.vue'),
        meta: { title: '修改专家', icon: 'expert' },
        hidden: true
      },
      {
        path: 'reponsesList',
        name: 'reponsesList',
        component: () => import('@/views/agrochemical/expert/reponsesList/index.vue'),
        meta: { title: '专家问答精选列表', icon: 'expert' },
        hidden: true
      },
      {
        path: 'addReponses',
        name: 'addReponses',
        component: () => import('@/views/agrochemical/expert/reponsesList/add.vue'),
        meta: { title: '添加专家问答精选', icon: 'expert' },
        hidden: true
      },
      {
        path: 'updateReponses',
        name: 'updateReponses',
        component: () => import('@/views/agrochemical/expert/reponsesList/update.vue'),
        meta: { title: '修改专家问答精选', icon: 'expert' },
        hidden: true
      },
      {
        path: 'rankingList',
        name: 'rankingList',
        component: () => import('@/views/agrochemical/ranking/index.vue'),
        meta: { title: '榜单列表', icon: 'ranking' }
      },
      {
        path: 'addRanking',
        name: 'addRanking',
        component: () => import('@/views/agrochemical/ranking/add.vue'),
        meta: { title: '添加榜单', icon: 'ranking' },
        hidden: true
      },
      {
        path: 'updateRanking',
        name: 'updateRanking',
        component: () => import('@/views/agrochemical/ranking/update.vue'),
        meta: { title: '修改榜单', icon: 'ranking' },
        hidden: true
      },
      {
        path: 'recommend',
        name: 'recommend',
        component: () => import('@/views/agrochemical/recommend/index.vue'),
        meta: { title: '推荐位管理', icon: 'recommend' }
      },
      {
        path: 'addRecommend',
        name: 'addRecommend',
        component: () => import('@/views/agrochemical/recommend/add.vue'),
        meta: { title: '添加推荐', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateRecommend',
        name: 'updateRecommend',
        component: () => import('@/views/agrochemical/recommend/update.vue'),
        meta: { title: '修改推荐', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'becomeRich',
        name: 'becomeRich',
        component: () => import('@/views/agrochemical/becomeRich/index.vue'),
        meta: { title: '致富列表', icon: 'becomeRich' }
      },
      {
        path: 'addBecomeRich',
        name: 'addBecomeRich',
        component: () => import('@/views/agrochemical/becomeRich/add.vue'),
        meta: { title: '添加致富', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateBecomeRich',
        name: 'updateBecomeRich',
        component: () => import('@/views/agrochemical/becomeRich/update.vue'),
        meta: { title: '修改致富', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'course',
        name: 'course',
        component: () => import('@/views/agrochemical/course/index.vue'),
        meta: { title: '课程订单列表', icon: 'course' }
      },
      {
        path: 'courseDetail',
        name: 'courseDetail',
        component: () => import('@/views/agrochemical/course/courseDetail.vue'),
        meta: { title: '课程订单详情', icon: 'course' },
        hidden: true
      },
      {
        path: 'order',
        name: 'order',
        component: () => import('@/views/agrochemical/order/index.vue'),
        meta: { title: '订单列表', icon: 'order' }
      },
      {
        path: 'orderDetail',
        name: 'orderDetail',
        component: () => import('@/views/agrochemical/order/orderDetail.vue'),
        meta: { title: '订单详情', icon: 'order' },
        hidden: true
      },
      {
        path: 'orderRefund',
        name: 'orderRefund',
        component: () => import('@/views/agrochemical/orderRefund/index.vue'),
        meta: { title: '退款申请列表', icon: 'orderRefund' }
      },
      {
        path: 'service',
        name: 'service',
        component: () => import('@/views/agrochemical/IM/index.vue'),
        meta: { title: '客服中心', icon: 'im' }
      },
      {
        path: 'hotSpot',
        name: 'hotSpot',
        component: () => import('@/views/agrochemical/hotSpot/index.vue'),
        meta: { title: '首页内容列表', icon: 'hotSpot' }
      },
      {
        path: 'addHotSpot',
        name: 'addHotSpot',
        component: () => import('@/views/agrochemical/hotSpot/add.vue'),
        meta: { title: '添加热点技术', icon: 'hotSpot' },
        hidden: true
      },
      {
        path: 'updateHotSpot',
        name: 'updateHotSpot',
        component: () => import('@/views/agrochemical/hotSpot/update.vue'),
        meta: { title: '修改热点技术', icon: 'hotSpot' },
        hidden: true
      },
      {
        path: 'courseList',
        name: 'courseList',
        component: () => import('@/views/agrochemical/courseList/index.vue'),
        meta: { title: '课程列表', icon: 'course' }
      },
      {
        path: 'studySituationList',
        name: 'studySituationList',
        component: () => import('@/views/agrochemical/courseList/studySituationList/index.vue'),
        meta: { title: '课程学习情况列表', icon: 'course' },
        hidden: true
      },
      {
        path: 'userStudySituationList',
        name: 'userStudySituationList',
        component: () => import('@/views/agrochemical/userList/studySituationList/index.vue'),
        meta: { title: '用户课程学习情况列表', icon: 'course' },
        hidden: true
      },
      {
        path: 'dynamicList',
        name: 'dynamicList',
        component: () => import('@/views/agrochemical/userList/dynamicList/index.vue'),
        meta: { title: '用户动态列表', icon: 'course' },
        hidden: true
      },
      {
        path: 'planTemplateList',
        name: 'planTemplateList',
        component: () => import('@/views/agrochemical/planTemplateList/index.vue'),
        meta: { title: '方案模板列表', icon: 'course' }
      },
      {
        path: 'addPlanTemplate',
        name: 'addPlanTemplate',
        component: () => import('@/views/agrochemical/planTemplateList/add.vue'),
        meta: { title: '添加方案模板', icon: 'hotSpot' },
        hidden: true
      },
      {
        path: 'updatePlanTemplate',
        name: 'updatePlanTemplate',
        component: () => import('@/views/agrochemical/planTemplateList/update.vue'),
        meta: { title: '修改方案模板', icon: 'hotSpot' },
        hidden: true
      },
      {
        path: 'phenologicalPhaseList',
        name: 'phenologicalPhaseList',
        component: () => import('@/views/agrochemical/planTemplateList/phenologicalPhaseList/index.vue'),
        meta: { title: '方案模板物候期列表', icon: 'course' },
        hidden: true
      },
      {
        path: 'addPhenologicalPhase',
        name: 'addPhenologicalPhase',
        component: () => import('@/views/agrochemical/planTemplateList/phenologicalPhaseList/add.vue'),
        meta: { title: '添加方案模板物候期', icon: 'hotSpot' },
        hidden: true
      },
      {
        path: 'updatePhenologicalPhase',
        name: 'updatePhenologicalPhase',
        component: () => import('@/views/agrochemical/planTemplateList/phenologicalPhaseList/update.vue'),
        meta: { title: '修改方案模板物候期', icon: 'hotSpot' },
        hidden: true
      },
      {
        path: 'farmOperationList',
        name: 'farmOperationList',
        component: () => import('@/views/agrochemical/planTemplateList/farmOperationList/index.vue'),
        meta: { title: '方案模板物候期农事操作列表', icon: 'course' },
        hidden: true
      },
      {
        path: 'addFarmOperation',
        name: 'addFarmOperation',
        component: () => import('@/views/agrochemical/planTemplateList/farmOperationList/add.vue'),
        meta: { title: '添加方案模板物候期农事操作', icon: 'hotSpot' },
        hidden: true
      },
      {
        path: 'updateFarmOperation',
        name: 'updateFarmOperation',
        component: () => import('@/views/agrochemical/planTemplateList/farmOperationList/update.vue'),
        meta: { title: '修改方案模板物候期农事操作', icon: 'hotSpot' },
        hidden: true
      },
      {
        path: 'farmOperationNatureList',
        name: 'farmOperationNatureList',
        component: () => import('@/views/agrochemical/planTemplateList/farmOperationNatureList/index.vue'),
        meta: { title: '方案模板物候期农事操作属性列表', icon: 'course' },
        hidden: true
      },
      {
        path: 'addFarmOperationNature',
        name: 'addFarmOperationNature',
        component: () => import('@/views/agrochemical/planTemplateList/farmOperationNatureList/add.vue'),
        meta: { title: '添加方案模板物候期农事操作属性', icon: 'hotSpot' },
        hidden: true
      },
      {
        path: 'updateFarmOperationNature',
        name: 'updateFarmOperationNature',
        component: () => import('@/views/agrochemical/planTemplateList/farmOperationNatureList/update.vue'),
        meta: { title: '修改方案模板物候期农事操作属性', icon: 'hotSpot' },
        hidden: true
      },
      {
        path: 'planApplyList',
        name: 'planApplyList',
        component: () => import('@/views/agrochemical/planApplyList/index.vue'),
        meta: { title: '方案申请列表', icon: 'course' }
      },
      {
        path: 'userPlanList',
        name: 'userPlanList',
        component: () => import('@/views/agrochemical/userPlanList/index.vue'),
        meta: { title: '用户方案列表', icon: 'course' }
      },
      {
        path: 'userPhenologiPhaseList',
        name: 'userPhenologiPhaseList',
        component: () => import('@/views/agrochemical/userPlanList/userPhenologiPhaseList/index.vue'),
        meta: { title: '用户方案物候期列表', icon: 'course' },
        hidden: true
      },
      {
        path: 'userFarmOperationList',
        name: 'userFarmOperationList',
        component: () => import('@/views/agrochemical/userPlanList/userFarmOperationList/index.vue'),
        meta: { title: '用户方案物候期农事操作列表', icon: 'course' },
        hidden: true
      },
      {
        path: 'userFarmOperationNatureList',
        name: 'userFarmOperationNatureList',
        component: () => import('@/views/agrochemical/userPlanList/userFarmOperationNatureList/index.vue'),
        meta: { title: '用户方案物候期农事操作属性列表', icon: 'course' },
        hidden: true
      },
      {
        path: 'inquiryList',
        name: 'inquiryList',
        component: () => import('@/views/agrochemical/inquiryList/index.vue'),
        meta: { title: '专家问诊列表', icon: 'inquiry' }
      },
      {
        path: 'inquiryList2',
        name: 'inquiryList2',
        component: () => import('@/views/agrochemical/inquiryList2/index.vue'),
        meta: { title: '普通问诊列表', icon: 'inquiry' }
      },
      {
        path: 'logoutList',
        name: 'logoutList',
        component: () => import('@/views/agrochemical/logoutList/index.vue'),
        meta: { title: '注销审核列表', icon: 'logout' }
      },
    ]
  },
  // 交易大厅
  {
    path: '/tradingFloor',
    component: Layout,
    redirect: '/tradingFloor/bidding',
    name: 'tradingFloor',
    meta: { title: '交易大厅', icon: 'trading_floor' },
    children: [
      {
        path: 'bidding',
        name: 'bidding',
        component: () => import('@/views/tradingFloor/bidding/index.vue'),
        meta: { title: '竞价列表', icon: 'bidding' }
      },
      {
        path: 'updateBidding',
        name: 'updateBidding',
        component: () => import('@/views/tradingFloor/bidding/update.vue'),
        meta: { title: '修改竞价', icon: 'bidding' },
        hidden: true
      },
      {
        path: 'clearance',
        name: 'clearance',
        component: () => import('@/views/tradingFloor/clearance/index.vue'),
        meta: { title: '清仓列表', icon: 'clearance' }
      },
      {
        path: 'updateClearance',
        name: 'updateClearance',
        component: () => import('@/views/tradingFloor/clearance/update.vue'),
        meta: { title: '修改清仓', icon: 'clearance' },
        hidden: true
      },
    ]
  },
  // 危险品列表
  {
    path: '/dangerous',
    component: Layout,
    redirect: '/dangerous/dangerousList',
    name: 'dangerous',
    meta: { title: '危险品列表', icon: 'dangerous_goods' },
    children: [
      {
        path: 'dangerousList',
        name: 'dangerousList',
        component: () => import('@/views/dangerous/index.vue'),
        meta: { title: '危险品列表', icon: 'dangerous' }
      },
      {
        path: 'addDangerous',
        name: 'addDangerous',
        component: () => import('@/views/dangerous/add.vue'),
        meta: { title: '添加危险品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateDangerous',
        name: 'updateDangerous',
        component: () => import('@/views/dangerous/update.vue'),
        meta: { title: '修改添加危险品', icon: 'product-add' },
        hidden: true
      },
    ]
  },
  // App管理
  {
    path: '/appManagement',
    component: Layout,
    redirect: '/appManagement/managementList',
    name: 'appManagement',
    meta: { title: 'App管理', icon: 'dangerous_goods' },
    children: [
      {
        path: 'managementList',
        name: 'managementList',
        component: () => import('@/views/appManagement/InformationManagement/index.vue'),
        meta: { title: 'App信息管理列表', icon: 'dangerous' }
      },
      {
        path: 'versionList',
        name: 'versionList',
        component: () => import('@/views/appManagement/version/index.vue'),
        meta: { title: 'App版本管理', icon: 'dangerous' }
      },
      {
        path: 'addVersion',
        name: 'addVersion',
        component: () => import('@/views/appManagement/version/add.vue'),
        meta: { title: '添加版本', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateVersion',
        name: 'updateVersion',
        component: () => import('@/views/appManagement/version/update.vue'),
        meta: { title: '修改版本', icon: 'product-add' },
        hidden: true
      },
    ]
  },
  // 百科列表
  {
    path: '/wikipedia',
    component: Layout,
    redirect: '/wikipedia/dangerousList',
    name: 'wikipedia',
    meta: { title: '百科列表', icon: 'wikipedia' },
    children: [
      {
        path: 'wikipediaList',
        name: 'wikipediaList',
        component: () => import('@/views/wikipedia/index.vue'),
        meta: { title: '百科列表', icon: 'wikipedia' }
      },
      {
        path: 'addWikipedia',
        name: 'addWikipedia',
        component: () => import('@/views/wikipedia/add.vue'),
        meta: { title: '添加百科', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateWikipedia',
        name: 'updateWikipedia',
        component: () => import('@/views/wikipedia/update.vue'),
        meta: { title: '修改百科', icon: 'product-add' },
        hidden: true
      },
    ]
  },
  // 山东企业站
  {
    path: '/sdEnterprise',
    component: Layout,
    redirect: '/sdEnterprise/newsList',
    name: 'sdEnterprise',
    meta: { title: '新闻', icon: 'product' },
    children: [
      {
        path: 'newsList',
        name: 'newsList',
        component: () => import('@/views/sdEnterprise/news/index.vue'),
        meta: { title: '新闻列表', icon: 'newsList' }
      },
      {
        path: 'addNews',
        name: 'addNews',
        component: () => import('@/views/sdEnterprise/news/add.vue'),
        meta: { title: '添加新闻', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateNews',
        name: 'updateNews',
        component: () => import('@/views/sdEnterprise/news/update.vue'),
        meta: { title: '修改新闻', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'productList',
        name: 'productList',
        component: () => import('@/views/sdEnterprise/product/index.vue'),
        meta: { title: '产品列表', icon: 'newsList' }
      },
      {
        path: 'addproduct',
        name: 'addproduct',
        component: () => import('@/views/sdEnterprise/product/add.vue'),
        meta: { title: '添加产品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateProduct',
        name: 'updateProduct',
        component: () => import('@/views/sdEnterprise/product/update.vue'),
        meta: { title: '修改产品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'teamwork',
        name: 'teamwork',
        component: () => import('@/views/sdEnterprise/supplyApply/index.vue'),
        meta: { title: '申请列表', icon: 'supplyApply' }
      },
      {
        path: 'supplyDetails',
        name: 'supplyDetails',
        component: () => import('@/views/sdEnterprise/supplyApply/supplyDetails.vue'),
        meta: { title: '申请详情', icon: 'supplyApply' },
        hidden: true
      },
      {
        path: 'messageBoard',
        name: 'messageBoard',
        component: () => import('@/views/sdEnterprise/messageBoard/index.vue'),
        meta: { title: '留言板', icon: 'message-board' }
      },
      {
        path: 'messageBoardDetails',
        name: 'messageBoardDetails',
        component: () => import('@/views/sdEnterprise/messageBoard/messageBoardDetails.vue'),
        meta: { title: '留言详情', icon: 'message-board' },
        hidden: true
      },
      {
        path: 'selectNews',
        name: 'selectNews',
        component: () => import('@/views/sdEnterprise/recommend/index.vue'),
        meta: { title: '推荐位管理', icon: 'recommend' }
      },
      {
        path: 'addRecommend',
        name: 'addRecommend',
        component: () => import('@/views/sdEnterprise/recommend/add.vue'),
        meta: { title: '添加推荐', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateRecommend',
        name: 'updateRecommend',
        component: () => import('@/views/sdEnterprise/recommend/update.vue'),
        meta: { title: '修改推荐', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'resultsList',
        name: 'resultsList',
        component: () => import('@/views/sdEnterprise/results/index.vue'),
        meta: { title: '效果展示', icon: 'newsList' }
      },
      {
        path: 'addResults',
        name: 'addResults',
        component: () => import('@/views/sdEnterprise/results/add.vue'),
        meta: { title: '添加效果展示', icon: 'Results-add' },
        hidden: true
      },
      {
        path: 'updateResults',
        name: 'updateResults',
        component: () => import('@/views/sdEnterprise/results/update.vue'),
        meta: { title: '修改效果展示', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'classroomList',
        name: 'classroomList',
        component: () => import('@/views/sdEnterprise/classroom/index.vue'),
        meta: { title: '农化课堂', icon: 'newsList' }
      },
      {
        path: 'addClassroom',
        name: 'addClassroom',
        component: () => import('@/views/sdEnterprise/classroom/add.vue'),
        meta: { title: '添加课堂', icon: 'classroom-add' },
        hidden: true
      },
      {
        path: 'updateClassroom',
        name: 'updateClassroom',
        component: () => import('@/views/sdEnterprise/classroom/update.vue'),
        meta: { title: '修改课堂', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'consultingList',
        name: 'consultingList',
        component: () => import('@/views/sdEnterprise/consulting/index.vue'),
        meta: { title: '技术咨询', icon: 'newsList' }
      },
      {
        path: 'addConsulting',
        name: 'addConsulting',
        component: () => import('@/views/sdEnterprise/consulting/add.vue'),
        meta: { title: '添加技术咨询', icon: 'classroom-add' },
        hidden: true
      },
      {
        path: 'updateConsulting',
        name: 'updateConsulting',
        component: () => import('@/views/sdEnterprise/consulting/update.vue'),
        meta: { title: '修改技术咨询', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'commentList',
        name: 'commentList',
        component: () => import('@/views/sdEnterprise/comment/index.vue'),
        meta: { title: '问答列表', icon: 'newsList' },
        hidden: true
      },
      {
        path: 'addComment',
        name: 'addComment',
        component: () => import('@/views/sdEnterprise/comment/add.vue'),
        meta: { title: '添加问答', icon: 'classroom-add' },
        hidden: true
      },
      {
        path: 'updateComment',
        name: 'updateComment',
        component: () => import('@/views/sdEnterprise/comment/update.vue'),
        meta: { title: '修改问答', icon: 'product-add' },
        hidden: true
      },


    ]
  },
  // 农业商城管理
  {
    path: '/agrochemicalMall',
    component: Layout,
    redirect: '/agrochemicalMall/mallInformation',
    name: 'agrochemicalMall',
    meta: { title: '农业商城管理', icon: 'agrochemical' },
    children: [
      {
        path: 'mallInformation',
        name: 'mallInformation',
        component: () => import('@/views/agrochemicalMall/information/index.vue'),
        meta: { title: '资讯列表', icon: 'cms-information' }
      },
      {
        path: 'addInformation',
        name: 'addInformation',
        component: () => import('@/views/agrochemicalMall/information/add.vue'),
        meta: { title: '添加资讯', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateInformation',
        name: 'updateInformation',
        component: () => import('@/views/agrochemicalMall/information/update.vue'),
        meta: { title: '修改资讯', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'classification',
        name: 'classification',
        component: () => import('@/views/agrochemicalMall/information/classification_management.vue'),
        meta: { title: '分类管理', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallUserList',
        name: 'mallUserList',
        component: () => import('@/views/agrochemicalMall/userList/index.vue'),
        meta: { title: '用户列表', icon: 'user_list' }
      },
      {
        path: 'mallTeamList',
        name: 'mallTeamList',
        component: () => import('@/views/agrochemicalMall/userList/team_management.vue'),
        meta: { title: '团队列表', icon: 'user_list' },
        hidden: true
      },
      {
        path: 'mallIntegralList',
        name: 'mallIntegralList',
        component: () => import('@/views/agrochemicalMall/userList/integralList.vue'),
        meta: { title: '积分统计列表', icon: 'user_list' },
        hidden: true
      },
      {
        path: 'mallOrder',
        name: 'mallOrder',
        component: () => import('@/views/agrochemicalMall/order/index.vue'),
        meta: { title: '订单列表', icon: 'order' }
      },
      {
        path: 'mallOrderDetail',
        name: 'mallOrderDetail',
        component: () => import('@/views/agrochemicalMall/order/orderDetail.vue'),
        meta: { title: '订单详情', icon: 'order' },
        hidden: true
      },
      {
        path: 'mallOrderRefund',
        name: 'mallOrderRefund',
        component: () => import('@/views/agrochemicalMall/orderRefund/index.vue'),
        meta: { title: '退款申请列表', icon: 'orderRefund' }
      },
      {
        path: 'mallProduct',
        name: 'mallProduct',
        component: () => import('@/views/agrochemicalMall/product/index.vue'),
        meta: { title: '商品列表', icon: 'product' }
      },
      {
        path: 'mallProductGiftList',
        name: 'mallProductGiftList',
        component: () => import('@/views/agrochemicalMall/product/giftList.vue'),
        meta: { title: '赠品列表', icon: 'product' },
        hidden: true
      },
      {
        path: 'mallProductSkuList',
        name: 'mallProductSkuList',
        component: () => import('@/views/agrochemicalMall/product/skuList.vue'),
        meta: { title: 'sku列表', icon: 'product' },
        hidden: true
      },
      {
        path: 'addMallProduct',
        name: 'addMallProduct',
        component: () => import('@/views/agrochemicalMall/product/add.vue'),
        meta: { title: '添加商品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateMallProduct',
        name: 'updateMallProduct',
        component: () => import('@/views/agrochemicalMall/product/update.vue'),
        meta: { title: '修改商品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallRecommend',
        name: 'mallRecommend',
        component: () => import('@/views/agrochemicalMall/recommend/index.vue'),
        meta: { title: '推荐位管理', icon: 'recommend' }
      },
      {
        path: 'addMallRecommend',
        name: 'addMallRecommend',
        component: () => import('@/views/agrochemicalMall/recommend/add.vue'),
        meta: { title: '添加推荐', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateMallRecommend',
        name: 'updateMallRecommend',
        component: () => import('@/views/agrochemicalMall/recommend/update.vue'),
        meta: { title: '修改推荐', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallWarehouse',
        name: 'mallWarehouse',
        component: () => import('@/views/agrochemicalMall/warehouse/index.vue'),
        meta: { title: '仓库列表', icon: 'depot' }
      },
      {
        path: 'addMallWarehouse',
        name: 'addMallWarehouse',
        component: () => import('@/views/agrochemicalMall/warehouse/add.vue'),
        meta: { title: '添加仓库', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateMallWarehouse',
        name: 'updateMallWarehouse',
        component: () => import('@/views/agrochemicalMall/warehouse/update.vue'),
        meta: { title: '修改仓库', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallReportList',
        name: 'mallReportList',
        component: () => import('@/views/agrochemicalMall/reportList/index.vue'),
        meta: { title: '报表列表', icon: 'report' }
      },
      {
        path: 'mallReportDetail',
        name: 'mallReportDetail',
        component: () => import('@/views/agrochemicalMall/reportList/orderDetail.vue'),
        meta: { title: '报表详情', icon: 'report' },
        hidden: true
      },
    ]
  },
  // 产业链
  {
    path: '/industrialChain',
    component: Layout,
    redirect: '/industrialChain/industrialChainList',
    name: 'industrialChain',
    meta: { title: '产业链', icon: 'industrial_chain' },
    children: [
      {
        path: 'industrialChainList',
        name: 'industrialChainList',
        component: () => import('@/views/industrialChain/industrialChainList/index.vue'),
        meta: { title: '产业链列表', icon: 'industrial_chain' }
      },
      {
        path: 'addIndustrialChain',
        name: 'addIndustrialChain',
        component: () => import('@/views/industrialChain/industrialChainList/add.vue'),
        meta: { title: '添加产业链', icon: 'industrial_chain' },
        hidden: true
      },
      {
        path: 'updateIndustrialChain',
        name: 'updateIndustrialChain',
        component: () => import('@/views/industrialChain/industrialChainList/update.vue'),
        meta: { title: '修改产业链', icon: 'industrial_chain' },
        hidden: true
      },
      {
        path: 'industrialChainProductList',
        name: 'industrialChainProductList',
        component: () => import('@/views/industrialChain/industrialChainList/productList/index.vue'),
        meta: { title: '产业链产品列表', icon: 'industrial_chain' },
        hidden: true
      },
      {
        path: 'addIndustrialChainProduct',
        name: 'addIndustrialChainProduct',
        component: () => import('@/views/industrialChain/industrialChainList/productList/add.vue'),
        meta: { title: '添加产业链产品', icon: 'industrial_chain' },
        hidden: true
      },
      {
        path: 'updateIndustrialChainProduct',
        name: 'updateIndustrialChainProduct',
        component: () => import('@/views/industrialChain/industrialChainList/productList/update.vue'),
        meta: { title: '修改产业链产品', icon: 'industrial_chain' },
        hidden: true
      },
      {
        path: 'industrialChainClinchList',
        name: 'industrialChainClinchList',
        component: () => import('@/views/industrialChain/industrialChainList/clinchList/index.vue'),
        meta: { title: '产业链产品成交列表', icon: 'industrial_chain' },
        hidden: true
      },
      {
        path: 'addIndustrialChainClinch',
        name: 'addIndustrialChainClinch',
        component: () => import('@/views/industrialChain/industrialChainList/clinchList/add.vue'),
        meta: { title: '添加产业链产品成交', icon: 'industrial_chain' },
        hidden: true
      },
      {
        path: 'updateIndustrialChainClinch',
        name: 'updateIndustrialChainClinch',
        component: () => import('@/views/industrialChain/industrialChainList/clinchList/update.vue'),
        meta: { title: '修改产业链产品成交', icon: 'industrial_chain' },
        hidden: true
      },
      {
        path: 'parkList',
        name: 'parkList',
        component: () => import('@/views/industrialChain/parkList/index.vue'),
        meta: { title: '园区列表', icon: 'park' }
      },
      {
        path: 'addPark',
        name: 'addPark',
        component: () => import('@/views/industrialChain/parkList/add.vue'),
        meta: { title: '添加园区', icon: 'park' },
        hidden: true
      },
      {
        path: 'updatePark',
        name: 'updatePark',
        component: () => import('@/views/industrialChain/parkList/update.vue'),
        meta: { title: '修改园区', icon: 'park' },
        hidden: true
      },
      {
        path: 'parkEnterpriseList',
        name: 'parkEnterpriseList',
        component: () => import('@/views/industrialChain/parkList/enterprise/index.vue'),
        meta: { title: '园区企业列表', icon: 'park' },
        hidden: true
      },
      {
        path: 'addParkEnterprise',
        name: 'addParkEnterprise',
        component: () => import('@/views/industrialChain/parkList/enterprise/add.vue'),
        meta: { title: '添加园区企业', icon: 'park' },
        hidden: true
      },
      {
        path: 'updateParkEnterprise',
        name: 'updateParkEnterprise',
        component: () => import('@/views/industrialChain/parkList/enterprise/update.vue'),
        meta: { title: '修改园区企业', icon: 'park' },
        hidden: true
      },
    ]
  },
  // 化浪金融
  {
    path: '/wxFinance',
    component: Layout,
    redirect: '/wxFinance/financeList',
    name: 'wxFinance',
    meta: { title: '化浪金融', icon: 'forum' },
    children: [
      {
        path: 'financeList',
        name: 'financeList',
        component: () => import('@/views/wxFinance/financeList.vue'),
        meta: { title: '金融列表', icon: 'serviceOrder' }
      },
      {
        path: 'operateList',
        name: 'operateList',
        component: () => import('@/views/wxFinance/operateList.vue'),
        meta: { title: '代运营列表', icon: 'serviceOrder' }
      },
      {
        path: 'factoringList',
        name: 'factoringList',
        component: () => import('@/views/wxFinance/factoringList.vue'),
        meta: { title: '保理列表', icon: 'serviceOrder' }
      },
    ]
  },
  // 行情报告
  {
    path: '/market',
    component: Layout,
    redirect: '/market/marketProductList',
    name: 'market',
    meta: { title: '行情报告', icon: 'market' },
    children: [
      {
        path: 'marketProductList',
        name: 'marketProductList',
        component: () => import('@/views/market/product/index.vue'),
        meta: { title: '产品列表', icon: 'market_product' }
      },
      {
        path: 'addMarketProduct',
        name: 'addMarketProduct',
        component: () => import('@/views/market/product/add.vue'),
        meta: { title: '添加产品', icon: 'market_product' },
        hidden: true
      },
      {
        path: 'updateMarketProduct',
        name: 'updateMarketProduct',
        component: () => import('@/views/market/product/update.vue'),
        meta: { title: '修改产品', icon: 'market_product' },
        hidden: true
      },
      {
        path: 'dailyPriceList',
        name: 'dailyPriceList',
        component: () => import('@/views/market/product/dailyPriceList/index.vue'),
        meta: { title: '产品日报价列表', icon: 'market_product' },
        hidden: true
      },
      {
        path: 'addDailyPrice',
        name: 'addDailyPrice',
        component: () => import('@/views/market/product/dailyPriceList/add.vue'),
        meta: { title: '添加产品日报价', icon: 'market_product' },
        hidden: true
      },
      {
        path: 'updateDailyPrice',
        name: 'updateDailyPrice',
        component: () => import('@/views/market/product/dailyPriceList/update.vue'),
        meta: { title: '修改产品日报价', icon: 'market_product' },
        hidden: true
      },
      {
        path: 'productPriceList',
        name: 'productPriceList',
        component: () => import('@/views/market/product/productPriceList/index.vue'),
        meta: { title: '产品报价列表', icon: 'market_product' },
        hidden: true
      },
      {
        path: 'addProductPrice',
        name: 'addProductPrice',
        component: () => import('@/views/market/product/productPriceList/add.vue'),
        meta: { title: '添加产品报价', icon: 'market_product' },
        hidden: true
      },
      {
        path: 'updateProductPrice',
        name: 'updateProductPrice',
        component: () => import('@/views/market/product/productPriceList/update.vue'),
        meta: { title: '修改产品报价', icon: 'market_product' },
        hidden: true
      },
      {
        path: 'primaryList',
        name: 'primaryList',
        component: () => import('@/views/market/primary/index.vue'),
        meta: { title: '大宗商品列表', icon: 'primary' }
      },
      {
        path: 'addPrimary',
        name: 'addPrimary',
        component: () => import('@/views/market/primary/add.vue'),
        meta: { title: '添加大宗商品', icon: 'primary' },
        hidden: true
      },
      {
        path: 'updatePrimary',
        name: 'updatePrimary',
        component: () => import('@/views/market/primary/update.vue'),
        meta: { title: '修改大宗商品', icon: 'primary' },
        hidden: true
      },
    ]
  },
  // 供应链管理
  {
    path: '/supplyChain',
    component: Layout,
    redirect: '/supplyChain/marketProductList',
    name: 'supplyChain',
    meta: { title: '供应链管理', icon: 'supply_chain' },
    children: [
      {
        path: 'depotList',
        name: 'depotList',
        component: () => import('@/views/supplyChain/depot/index.vue'),
        meta: { title: '仓储列表', icon: 'depot' }
      },
      {
        path: 'depotDetail',
        name: 'depotDetail',
        component: () => import('@/views/supplyChain/depot/depotDetail.vue'),
        meta: { title: '仓储详情', icon: 'depot' },
        hidden: true
      },
    ]
  },
  // 供求模块
  {
    path: '/supplyDemandModule',
    component: Layout,
    redirect: '/supplyDemandModule/supplyDemandData',
    name: 'supplyDemandModule',
    meta: { title: '供求模块', icon: 'supply_demand' },
    children: [
      {
        path: 'supplyDemandData',
        name: 'supplyDemandData',
        component: () => import('@/views/supplyDemandModule/index.vue'),
        meta: { title: '供求模块首页', icon: 'supply_demand' }
      },
      {
        path: 'supplyList',
        name: 'supplyList',
        component: () => import('@/views/supplyDemandModule/supply/index.vue'),
        meta: { title: '供应列表', icon: 'auditlist' }
      },
      {
        path: 'supplyReplacement',
        name: 'supplyReplacement',
        component: () => import('@/views/supplyDemandModule/supply/supplyReplacement.vue'),
        meta: { title: '公司代发供应列表', icon: 'auditlist' },
        hidden: true
      },
      {
        path: 'supplySkuList',
        name: 'supplySkuList',
        component: () => import('@/views/supplyDemandModule/supply/skuList.vue'),
        meta: { title: '公司商品列表', icon: 'auditlist' },
        hidden: true
      },
      {
        path: 'publishSupply',
        name: 'publishSupply',
        component: () => import('@/views/supplyDemandModule/supply/publishSupply.vue'),
        meta: { title: '供应代发', icon: 'auditlist' },
        hidden: true
      },
      {
        path: 'buyList',
        name: 'buyList',
        component: () => import('@/views/supplyDemandModule/buy/index.vue'),
        meta: { title: '求购列表', icon: 'auditlist' }
      },
      {
        path: 'buyReplacement',
        name: 'buyReplacement',
        component: () => import('@/views/supplyDemandModule/buy/buyReplacement.vue'),
        meta: { title: '公司代发求购列表', icon: 'auditlist' },
        hidden: true
      },
      {
        path: 'buyFollowList',
        name: 'buyFollowList',
        component: () => import('@/views/supplyDemandModule/buy/buyFollowList.vue'),
        meta: { title: '求购匹配跟进列表', icon: 'auditlist' },
        hidden: true
      },
      {
        path: 'publishBuy',
        name: 'publishBuy',
        component: () => import('@/views/supplyDemandModule/buy/publishBuy.vue'),
        meta: { title: '求购代发', icon: 'auditlist' },
        hidden: true
      },
      {
        path: 'demandResponseList',
        name: 'demandResponseList',
        component: () => import('@/views/supplyDemandModule/demandResponse/index.vue'),
        meta: { title: '需求响应', icon: 'auditlist' }
      },
      {
        path: 'supplyDetails',
        name: 'supplyDetails',
        component: () => import('@/views/supplyDemandModule/demandResponse/supplyDetails.vue'),
        meta: { title: '代发供应详情', icon: 'auditlist' },
        hidden: true
      },
      {
        path: 'buyDetails',
        name: 'buyDetails',
        component: () => import('@/views/supplyDemandModule/demandResponse/buyDetails.vue'),
        meta: { title: '代发求购详情', icon: 'auditlist' },
        hidden: true
      },
      {
        path: 'sdCompletedList',
        name: 'sdCompletedList',
        component: () => import('@/views/supplyDemandModule/sdCompletedList.vue'),
        meta: { title: '供求模块已完成列表', icon: 'auditlist' },
      },
      {
        path: 'sdReviewList',
        name: 'sdReviewList',
        component: () => import('@/views/supplyDemandModule/sdReviewList.vue'),
        meta: { title: '供求模块待审核列表', icon: 'auditlist' }
      },
      {
        path: 'supplyDemandList',
        name: 'supplyDemandList',
        component: () => import('@/views/supplyDemandModule/supplyDemandList.vue'),
        meta: { title: '供求模块供求列表', icon: 'auditlist' }
      },
      {
        path: 'sdList',
        name: 'sdList',
        component: () => import('@/views/supplyDemandModule/sdList.vue'),
        meta: { title: '供求模块全站供求列表', icon: 'auditlist' }
      },
    ]
  },
  // 抽奖模块
  {
    path: '/luckyDraw',
    component: Layout,
    redirect: '/luckyDraw/prizesList',
    name: 'luckyDraw',
    meta: { title: '抽奖模块', icon: 'lucky_draw' },
    children: [
      {
        path: 'prizesList',
        name: 'prizesList',
        component: () => import('@/views/luckyDraw/prizesList/index.vue'),
        meta: { title: '奖品列表', icon: 'prizes' }
      },
      {
        path: 'addPrizes',
        name: 'addPrizes',
        component: () => import('@/views/luckyDraw/prizesList/add.vue'),
        meta: { title: '添加奖品', icon: 'prizes' },
        hidden: true
      },
      {
        path: 'updatePrizes',
        name: 'updatePrizes',
        component: () => import('@/views/luckyDraw/prizesList/update.vue'),
        meta: { title: '修改奖品', icon: 'prizes' },
        hidden: true
      },
      {
        path: 'schemeList',
        name: 'schemeList',
        component: () => import('@/views/luckyDraw/schemeList/index.vue'),
        meta: { title: '方案列表', icon: 'scheme' }
      },
      {
        path: 'addScheme',
        name: 'addScheme',
        component: () => import('@/views/luckyDraw/schemeList/add.vue'),
        meta: { title: '添加方案', icon: 'scheme' },
        hidden: true
      },
      {
        path: 'updateScheme',
        name: 'updateScheme',
        component: () => import('@/views/luckyDraw/schemeList/update.vue'),
        meta: { title: '修改方案', icon: 'scheme' },
        hidden: true
      },
      {
        path: 'recordList',
        name: 'recordList',
        component: () => import('@/views/luckyDraw/recordList.vue'),
        meta: { title: '抽奖记录列表', icon: 'record' }
      },
    ]
  },
  // 集采模块
  {
    path: '/collection',
    component: Layout,
    redirect: '/collection/collectionList',
    name: 'collection',
    meta: { title: '集采模块', icon: 'collection' },
    children: [
      {
        path: 'collectionAuditList',
        name: 'collectionAuditList',
        component: () => import('@/views/collection/collectionAuditList.vue'),
        meta: { title: '集采审核列表', icon: 'collection' }
      },
      {
        path: 'collectionList',
        name: 'collectionList',
        component: () => import('@/views/collection/collectionList.vue'),
        meta: { title: '集采列表', icon: 'collection' }
      },
      {
        path: 'collectionDetails',
        name: 'collectionDetails',
        component: () => import('@/views/collection/collectionDetails.vue'),
        meta: { title: '集采详情', icon: 'collection' },
        hidden: true
      },
    ]
  },
  // 委托采购模块
  {
    path: '/entrust',
    component: Layout,
    redirect: '/entrust/entrustList',
    name: 'entrust',
    meta: { title: '委托采购模块', icon: 'entrust' },
    children: [
      {
        path: 'entrustList',
        name: 'entrustList',
        component: () => import('@/views/entrust/index.vue'),
        meta: { title: '委托采购列表', icon: 'entrust' }
      },
    ]
  },
  // 积分管理
  {
    path: '/point',
    component: Layout,
    redirect: '/point/pointBreakdown',
    name: 'point',
    meta: { title: '积分管理', icon: 'point' },
    children: [
      {
        path: 'pointSetting',
        name: 'pointSetting',
        component: () => import('@/views/point/setting'),
        meta: { title: '积分配置', icon: 'order-setting' }
      },
      {
        path: 'pointBreakdown',
        name: 'pointBreakdown',
        component: () => import('@/views/point/breakdown'),
        meta: { title: '积分明细列表', icon: 'product-list' }
      },
      {
        path: 'pointPresentList',
        name: 'pointPresentList',
        component: () => import('@/views/point/present/index.vue'),
        meta: { title: '积分礼品列表', icon: 'product' }
      },

      {
        path: 'pointRecordList',
        name: 'pointRecordList',
        component: () => import('@/views/point/present/recordList.vue'),
        meta: { title: '礼品兑换记录列表', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'addPointPresent',
        name: 'addPointPresent',
        component: () => import('@/views/point/present/add.vue'),
        meta: { title: '添加礼品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updatePointPresent',
        name: 'updatePointPresent',
        component: () => import('@/views/point/present/update.vue'),
        meta: { title: '修改礼品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'pointOrderList',
        name: 'pointOrderList',
        component: () => import('@/views/point/order/index'),
        meta: { title: '积分订单列表', icon: 'product-list' }
      },
      {
        path: 'pointOrderDetail',
        name: 'pointOrderDetail',
        component: () => import('@/views/point/order/orderDetail'),
        meta: { title: '积分订单详情' },
        hidden: true
      },
    ]
  },
  { path: '*', redirect: '/404', hidden: true }
]

export default new Router({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

